import {useState} from 'react'
import {useEffect} from 'react'
import styles from './Home.module.scss'
import {useParams} from 'react-router-dom'
import {GradientBtn, H4, ModalView, OutlinedInput, P, SubHeader, useFetch} from '@curawella/curawella-components'
import {Image} from 'react-bootstrap'
import passwordImg from './assets/imgs/password.png'
import cancelImg from './assets/imgs/cancel.svg'

const Home = () => {
    const [passwords, setPasswords] = useState({password1: null, password2: null})
    const [errors, setErrors] = useState({password1: null, password2: null})

    const [showModal, setShowModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [globalError, setGlobalError] = useState('')

    const [data, loading, error, execute] = useFetch()

    const uid = useParams().uid

    useEffect(() => {
        console.log('params:  ', uid)
    }, [uid])

    const checkValues = () => {
        let errs = {password1: null, password2: null}
        let result = true
        setErrors(errs)
        setGlobalError('')
        if (!passwords.password1 || !passwords.password2) {
            for (const key in passwords) {
                const element = passwords[key]

                if (!element) {
                    errs[key] = 'مطلوب'
                    result = false
                }
            }
            setErrors(errs)
            return result
        }

        if (passwords.password1 !== passwords.password2) {
            setErrors({password1: null, password2: 'كلمة المرور غير متطابقة'})
            result = false
            return result
        } else if (passwords.password1.length < 6) {
            setGlobalError('كلمة المرور يجب ان تحتوى على الأقل على 6 احرف او ارقام ')
            result = false
            return result
        }

        return result
    }
    const submitForm = () => {
        if (checkValues()) {
            console.log('success')
            execute('post', `v2/user/auth/forgetPassword/${uid}`, {password: passwords.password1})
        }
    }

    // const closeWindow = () => {
    //     window.close()
    // }

    useEffect(() => {
        if (data) {
            console.log('submit successfully: ', data)
            setShowModal(true)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error in submit: ', error)
            if (error.code === 'not-valid') {
                setShowErrorModal(true)
            } else if (error.err && error.err === 'The password must be a string with at least 6 characters.') {
                setGlobalError('كلمة المرور يجب ان تحتوى على الأقل على 6 احرف او ارقام ')
            }
        }
    }, [error])

    return (
        <div className={styles['home-container']}>
            <SubHeader title='تغيير كلمة المرور' />
            <div className={styles['forget-password-content']}>
                <div className={styles['data-container']}>
                    <H4>ادخل كلمة المرور الجديدة</H4>

                    {/* <P type='p1'>ادخل كلمة المرور الجديدة</P> */}
                    <OutlinedInput
                        title='كلمة المرور'
                        value={passwords.password1}
                        setValue={(e) => setPasswords({...passwords, password1: e.target.value})}
                        type='password'
                        error={errors.password1}
                    />
                    <OutlinedInput
                        title='تأكيد كلمة المرور'
                        value={passwords.password2}
                        setValue={(e) => setPasswords({...passwords, password2: e.target.value})}
                        type='password'
                        error={errors.password2}
                    />
                    {globalError && (
                        <P type='p2' other='error-text'>
                            {globalError}
                        </P>
                    )}
                    <GradientBtn
                        title={loading ? 'جار الأرسال' : 'ارسال'}
                        type='gradient'
                        align='start'
                        action={submitForm}
                        disabled={loading ? true : false}
                    />
                </div>
                <Image loading='lazy' className={styles['password-img']} alt='password-img' src={passwordImg} />
            </div>

            {showModal && (
                <ModalView
                    show={showModal}
                    setShow={(value) => {}}
                    type='success'
                    title='تغيير كلمة المرور'
                    description='تم تغيير كلمة المرور بنجاح'
                />
            )}
            {showErrorModal && (
                <ModalView
                    show={showErrorModal}
                    setShow={(value) => {}}
                    type='customView'
                    customView={
                        <div className={styles['error-container']}>
                            <Image loading='lazy' className={styles['cancel-img']} alt='cancel-img' src={cancelImg} />
                            <P type='title'>انتهت صلاحية الرابط قم بطلب جديد</P>
                        </div>
                    }
                />
            )}
        </div>
    )
}

export default Home
