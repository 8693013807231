import './App.scss'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Home from './pages/home/Home'

function App() {
    if (process.env && process.env.NODE_ENV !== 'development') {
        console.log = () => {}
    }

    return (
        <div className='app-container'>
            <Router>
                <Route path='/:uid' component={Home} />
            </Router>
        </div>
    )
}

export default App
